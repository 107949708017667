import { meta } from './meta';
const buildInfo = require('./buildInfo');
const proxy = require('../../proxies/proxy-prod.conf.json');

export const environment = {
  apiServer: proxy['/api'].target,
  version: buildInfo.version,
  buildDate: buildInfo.buildDate,
  enableServiceWorker: false,
  name: 'production',
  production: true,
  transactGroupId: 5191556013228032,
  transactBaseUrl: 'https://transact.io',
  postdBaseUrl: 'https://postd.io',
  test: false,
  meta,
};
